import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerPageBannerWithTitleDescription from "../components/common/InnerPageBannerWithTitleDescription"
import { PrivacyPolicyContent } from "../Content/PrivacyPolicy"
import InnerPageBodyWithToc from "../components/common/InnerPageBodyWithToc"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description={`Your privacy is our duty. Our policy outlines how we collect, use, and protect your personal information. Learn how we secure your data with Synkli.`}
      />

      <InnerPageBannerWithTitleDescription
        title={PrivacyPolicyContent.title}
        description={PrivacyPolicyContent.description}
        extraDescription={PrivacyPolicyContent.extraDescription}
        extraDescriptionEC={`font-[600]`}
        image={{
          path: PrivacyPolicyContent.image.path,
          alt: PrivacyPolicyContent.image.alt,
        }}
        imageWrapperEC={`self-end hidden md:block`}
      />

      <div className="synkli-section--horizontal-space mt-10 md:mt-20 mb-10 md:mb-20">
        <hr />
      </div>

      <InnerPageBodyWithToc page={PrivacyPolicyContent} />
    </Layout>
  )
}

export default PrivacyPolicy
